var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"tp-table-scroll tp-table__row-pointer datatable px-3 py-2",attrs:{"headers":_vm.isViewedCreatedPrice ? _vm.headers : _vm.saleHeaders,"items":_vm.productOptions,"loading":_vm.productOptionStatusRequest.value === 'loading-getProductOptions',"options":_vm.dataTableOptions,"calculate-widths":"","disable-pagination":"","hide-default-footer":"","item-key":"id","loading-text":"Đang tải dữ liệu","no-data-text":"Không có dữ liệu","no-results-text":"Không tìm thấy kết quả phù hợp","show-select":""},on:{"update:options":function($event){return _vm.updateSort($event)},"click:row":function($event){return _vm.viewDetail($event)}},scopedSlots:_vm._u([{key:"item.SKU",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"copied-label font-weight-bold",on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipboard(item.SKU)}}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.SKU)+" ")])]}}],null,true)},[_c('span',[_vm._v("Sao chép")])])],1),_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"copied-label",on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipboard(item.MPN)}}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.MPN ? item.MPN : "N/A")+" ")])]}}],null,true)},[_c('span',[_vm._v("Sao chép")])])],1)]}},{key:"item.product_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.product_name)+" ")]),_c('div',{staticClass:"text-subtitle-2 font-weight-light grey--text text--darken-2 tag-p__mb-0",domProps:{"innerHTML":_vm._s(item.specs)}}),(item.promotion_gifts)?_c('div',{staticClass:"text-subtitle-2 font-weight-light red--text text--accent-2"},[_vm._v(" "+_vm._s(item.promotion_gifts)+" ")]):_vm._e()])]}},{key:"item.date_ton_lau",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.date_ton_lau === "" ? 0 : item.date_ton_lau)+" ")]}},{key:"item.ready_to_sell_qty",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold rounded px-2",attrs:{"color":"green lighten-4","label":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.openModalSerialPicker(item)}}},[_vm._v(" "+_vm._s(item.ready_to_sell_qty)+" ")])]}},{key:"item.ordered_qty",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold rounded px-2",attrs:{"color":"red lighten-4","label":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.openModalSerialPicker(item)}}},[_vm._v(" "+_vm._s(item.ordered_qty)+" ")])]}},{key:"item.waiting_ordered_qty",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold rounded px-2",attrs:{"color":"blue lighten-4","label":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.openModalSerialPicker(item)}}},[_vm._v(" "+_vm._s(item.waiting_ordered_qty)+" ")])]}},{key:"item.exhibited_qty",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold rounded px-2",attrs:{"color":"deep-purple lighten-4","label":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.openModalSerialPicker(item)}}},[_vm._v(" "+_vm._s(item.exhibited_qty)+" ")])]}},{key:"item.so_luong_dang_ve",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold rounded px-2",attrs:{"color":"orange lighten-4","label":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.openModalSerialPicker(item)}}},[_vm._v(" "+_vm._s(item.so_luong_dang_ve)+" ")])]}},{key:"item.gia_von_trung_binh",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.gia_von_trung_binh))+" ")])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('div',[(_vm.calculateSalePrice(item.price, item.promotions) !== item.price)?[_c('div',[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(_vm.calculateSalePrice(item.price, item.promotions)))+" ")]),_c('div',{staticClass:"text-body-2 grey--text text--darken-2 text-decoration-line-through"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.price))+" ")])]:_c('div',[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.price))+" ")])],2)]}},{key:"item.sale_price",fn:function(ref){
var item = ref.item;
return [(item.sale_price)?_c('div',[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.sale_price))+" "),(!(_vm.isSalePermission && _vm.isMarketingPermission))?_c('div',{staticClass:"font-weight-medium text-subtitle-2",class:_vm.getProductMargin(item) >= 0 ? 'green--text' : 'red--text'},[(item.gia_von_trung_binh)?_c('div',[_vm._v(" "+_vm._s(_vm.getProductMargin(item))+"% ")]):_c('div',[_vm._v(" N/A ")])]):_vm._e()]):_c('div',[_vm._v(" N/A ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.urls)?_c('button-view-sku-in-website',{attrs:{"urls":item.urls}}):_vm._e()]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }